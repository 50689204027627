<template>
  <div id="logins">
    <img class="head_img" src="@img/login/login.png" alt="" />
    <div class="table">
      <!-- 标题 -->
      <p class="head_name">{{language.l_100}}</p>
      <van-form @submit="onSubmit" ref="form">
         <!-- 邮箱 -->
        <p class="mailbox">{{language.l_101}}</p>
        <van-field v-model="params.email" :placeholder="language.l_102" :rules="formVerify.email" name="email" left-icon="invition" class="email" autocomplete="off" clearable/>
        <!-- 密码 -->
        <p class="mailbox">{{language.l_92}}</p>
        <!-- 请输入您的密码 -->
        <van-field @click-right-icon="psdFlag = !psdFlag" v-model="params.pwd" :placeholder="language.l_93" :rules="formVerify.pwd" name="pwd" :type="psdFlag ? 'password' : ''" autocomplete="off" left-icon="lock" class="email" clearable>
          <template #right-icon><van-icon :name="psdFlag ? 'closed-eye' : 'eye'" color="#FB2C37"/></template>
        </van-field>
        <!-- 重新输入密码 -->
        <p class="mailbox">{{language.l_94}}</p>
        <!-- 确认密码 -->
        <van-field @click-right-icon="rePwdFlag = !rePwdFlag" v-model="params.rePwd" :rules="formVerify.pwd" :placeholder="language.l_91" name="rePwd" :type="rePwdFlag ? 'password' : ''" autocomplete="off" left-icon="lock" clearable class="email">
          <template #right-icon><van-icon :name="rePwdFlag ? 'closed-eye' : 'eye'" color="#FB2C37"/></template>
        </van-field>
        <!-- 邮箱验证 -->
        <p class="mailbox">{{language.l_103}}</p>
        <van-field v-model="params.code" :placeholder="language.l_104" :rules="formVerify.code" name="code" left-icon="lock" class="email" autocomplete="off" clearable>
          <!-- 获取验证码 -->
          <template #button>
            <van-button @click.prevent="codeIng" :disabled="btnFlag" size="mini">{{ btnFlag ? `${time}${language.l_105}` : language.l_106 }}</van-button>
          </template>
        </van-field>
        <!-- 提交按钮 -->
        <div class="buttom_lay">
          <van-button native-type="submit">{{language.l_99_1}}</van-button>
        </div>
        <!-- 返回按钮 -->
        <div class="returnBut">
          <van-button @click="goBack">{{language.l_99_2}}</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import {sms_code, updata_pwd} from "@api";
export default {
  data() {
    return {
      params: {
        pwd: "",
        rePwd: "",
        email: "", //邮箱
        code: "", //验证码
        type: "rePwd",
      },
      time: "90",
      timeId: null,
      btnFlag: false,
      language: this.$store.getters.language,
      psdFlag: true,
      rePwdFlag: true
    };
  },
  methods: {
    onSubmit(values) {  //提交表单
      const {pwd, rePwd} = this.params;
      if(pwd == rePwd){
        updata_pwd(this.params) //密码修改
        .then((res) => {
          this.$toast.success(this.language.l_97)  //修改成功
          this.$router.push({name: 'Login'})
        })
      }else{
        this.$toast(this.language.l_98);  //密码不同，请重新输入
      }
    },
    codeIng() { //验证
      this.$refs.form.validate("email").then(() => {
        const {email, type} = this.params;
        sms_code({email, type}).then(() => {})
        this.btnFlag = true;
        let count = this.time;
        this.timeId = setInterval(() => {
          if (count == 1) {
            clearInterval(this.timeId);
            return (this.btnFlag = false);
          }
          count--;
          count = `${count}`.padStart(2, "0");
          this.time = count;
        }, 1000);
      })
    },
    goBack(){  //返回上一页
      this.$router.go(-1)
    }
  },
  computed: {
    formVerify() { //校验
      const rules = {
        email: [{required: true, message: this.language.l_107}],  //邮箱不能为空
        code: [{required: true, message: this.language.l_108}],  //验证码不能为空
        pwd: [{required: true, message: this.language.l_99}],  //密码不能为空
      };
      return rules;
    },
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.timeId);
  },
};
</script>
<style lang='less' scoped>
#logins {
  background: #fff;
  // height: 100%;
  .head_img {
    width: 100%;
    height: 244px;
  }
  .table {
    padding: 20px 25.5px;
    .head_name {
      font-size: 24px;
      font-weight: bold;
      color: #013358;
    }
    .mailbox {
      padding: 30px 0 10px 0;
      font-size: 14px;
      font-weight: bold;
      color: #013358;
    }
    .email {
      display: flex;
      align-items: center;
      background: #fef3f1;
      border-radius: 8px;
      /deep/ input::-webkit-input-placeholder {
        color: @c_deep;
      }
      /deep/ .van-field__control {
        padding-left: 8px;
      }
      .forgets {
        min-width: 60px;
        text-align: center;
        color: @c_deep;
        height: 24px;
        padding-left: 6px;
        border-left: 2px solid @c_deep;
      }
      /deep/ .van-field__left-icon {
        i {
          color: @c_deep;
        }
      }
    }
    /deep/ .van-field__button .van-button {
      min-width: 70px;
    }
    /deep/ .van-field__button {
      display: flex;
      align-items: center;
      height: 24px;
      border-left: 1px solid @c_deep;
      .van-button--default {
        background-color: transparent !important;
        font-size: 12px;
        color: @c_deep;
        border: none;
      }
    }
    .buttom_lay {
      /deep/ button {
        width: 100%;
        border-radius: 8px;
        margin: 40px 0 0 0;
        color: #fff;
        background: @c_deep;
      }
    }
    .returnBut{
       /deep/ button {
        width: 100%;
        border-radius: 8px;
        margin: 10px 0;
        color: #fff;
        background: @c_deep;
      }
    }
    /deep/ .van-field__error-message {
      padding-left: 8px;
    }
  }
}
</style>
